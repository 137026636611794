<template>
  <div v-if="course && course.info" class="course-info">
    <course-header-info
        :course="course"
        :courseInfo="course.info"
        :show-btn-buy="true"/>
<!--    <course-thanks-info-->
<!--        :thanksCourse="course.info.thanksToCourse"/>-->
<!--    <course-description-info-->
<!--        id="description"-->
<!--        :tasks-count="course.tasksCount"-->
<!--        :videos-count="course.videosCount"/>-->
    <course-study-info
        :learning-topics="course.info.learningTopics"/>
    <course-program-info
        id="programInfo"
        :available="course.info.isPurchasable"
        :testsCount="course.info.testsCount"
        :course="course.info"/>
<!--    <course-author-info-->
<!--        id="author"-->
<!--        :show-btn-buy="true"-->
<!--        :price="course.price"-->
<!--        :available="course.info.isPurchasable"-->
<!--        :author="course.info.author"/>-->
<!--    <course-author-certificates-info-->
<!--        :photos="course.info.author.certificatesPhoto"/>-->
<!--    <course-reviews-info-->
<!--        id="reviews"-->
<!--        :available="course.info.isPurchasable"-->
<!--        :reviews="course.info.reviews"/>-->
<!--    <similar-courses-info-->
<!--        :course-category-id="course.categories[0]"/>-->

    <buy-course-popup :courseInfo="course"/>
    <not-available-course/>
  </div>
  <div v-else v-loading="true" class="course-info__preloader"></div>
</template>

<script>
import CourseHeaderInfo from "./components/CourseHeaderInfo";
// import CourseDescriptionInfo from "./components/CourseDescriptionInfo";
import CourseStudyInfo from "./components/CourseStudyInfo";
import CourseProgramInfo from "./components/CourseProgramInfo/CourseProgramInfo";
// import CourseAuthorInfo from "./components/CourseAuthorInfo";
// import CourseReviewsInfo from "./components/CourseReviewsInfo";
// import CourseThanksInfo from "./components/CourseThanksInfo";
// import SimilarCoursesInfo from "./components/SimilarCoursesInfo";
import BuyCoursePopup from "@/app/user/myCourses/components/BuyCoursePopup";
import NotAvailableCourse from "../myCourses/components/NotAvailableCourse";
import {coursesService} from "@/app/user/courses/courses.service";
// import CourseAuthorCertificatesInfo from "./components/CourseAuthorCertificatesInfo";

export default {
  name: "CourseInfo",
  components: {
    // CourseAuthorCertificatesInfo,
    // CourseThanksInfo,
    // SimilarCoursesInfo,
    // CourseReviewsInfo,
    // CourseAuthorInfo,
    CourseStudyInfo,
    CourseProgramInfo,
    // CourseDescriptionInfo,
    CourseHeaderInfo,
    NotAvailableCourse,
    BuyCoursePopup
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth
    },
    courseId() {
      return Number.parseInt(this.$route.params.courseId)
    },
    course() {
      return this.$store.state.courses.courses.find(item => item.id === this.courseId)
    },
    isLoggedIn() {
      return this.$store.state.user.isLoggedIn
    },
    isMyCourse() {
      return this.$store.state.myCourses.courses.find(item => item.id === this.courseId) && this.isLoggedIn
    }
  },
  mounted() {
    this.checkMyCourse()
    coursesService.getCourseInfo(this.courseId).then(()=>{

      if(this.$route.query['enroll'] !== undefined)
        this.openPaymentModal()
    })


  },
  watch:{
    '$route.params'(){
      this.checkMyCourse()
      coursesService.getCourseInfo(this.courseId)
    }
  },
  methods: {
    checkMyCourse() {
      if (this.isMyCourse) {
        this.$router.replace(`/course-info/${this.courseId}/program`)
      }
    },
    openPaymentModal() {
      this.course.info.isPurchasable ? this.buyCourse() : this.NotAvailableCourse()
    },
    NotAvailableCourse() {
      this.$store.commit('myCourses.setShowNotAvailableCourse', true)
    },
    buyCourse() {
      this.$store.commit('myCourses.setShowBuyCoursePopup', true)
    },
  }
}
</script>

<style scoped lang="scss">
.course-info {
  width: 100%;

  box-sizing: border-box;
  padding-top: 30px;
  transition: 0.2s;

  @media screen and (max-width: 980px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media screen and (max-width: 670px) {
    padding-top: 72px;
  }
  @media screen and (max-width: 600px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (max-width: 480px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 400px) {
    padding-top: 28px;
  }
  @media screen and (max-width: 360px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.course-info__preloader{
  height: 100vh;
  width: 100%;
}
</style>
