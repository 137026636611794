<template>
  <div class="course-header-info">
    <div class="course-header-info__info"
         :class="{'course-header-info__info--w100':  !courseInfo.videoIntro.isVisible || !courseInfo.videoIntro.path}">

      <div class="course-header-info__category">
        <div>курс</div>
        <div v-if="categoryName">{{ categoryName }}</div>
      </div>

      <div class="course-header-info__title-course">{{ course.name }}</div>

      <div v-if="showBtnBuy" class="course-header-info__buy-course">
<!--        <div @click="clickButtonBuy" class="course-header-info__buy-btn">-->
<!--          Записаться на курс-->
<!--        </div>-->
<!--        <div class="course-header-info__price">-->
<!--          {{ course.price }} ₽-->
<!--        </div>-->

      </div>

      <div class="course-header-info__lesson-count">
        <div>
          {{ course.lessonsCount || 0 }}
          <span>Уроков</span>
        </div>
<!--        <div>-->
<!--          {{ course.tasksCount }}-->
<!--          <span>Заданий</span>-->
<!--        </div>-->
      </div>

    </div>

    <div v-if="courseInfo.videoIntro.isVisible && courseInfo.videoIntro.path && courseInfo.videoIntro.preview"
         class="course-header-info__video-preview">
      <div class="course-header-info__preview-img">
        <img :src="previewImage" alt="">
        <div class="play-preview"
             @click="showModal = true">
          <img src="@/assets/img/user/icon-play.svg" alt="">
        </div>
      </div>
      <div class="course-header-info__author">
        <div class="course-header-info__author-name">
          {{ courseInfo.author.name }}
          <img src="@/assets/img/user/author-check.svg" alt="check">
        </div>
        <div class="course-header-info__author-position">
          {{ courseInfo.author.position }}, автор курса
        </div>
      </div>
    </div>

    <div class="course-header-info__lesson-count">
      <div>
        {{ course.lessonsCount || 0 }}
        <span>Уроков</span>
      </div>
    </div>

    <transition name="el-zoom-in-center" :duration="22000" mode="out-in">
      <course-video-intro-modal v-if="showModal"
                                :path="courseInfo.videoIntro.path"
                                @closeModal="showModal = false"/>
      </transition>
  </div>
</template>

<script>

import CourseVideoIntroModal from "./CourseVideoIntroModal";

export default {
  name: "CourseHeaderInfo",
  components: {
    CourseVideoIntroModal
  },
  props: {
    course: {type: Object},
    courseInfo: {type: Object},
    showBtnBuy: {type: Boolean, default: false},
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth
    },
    discountPrice() {
      const discount = (+this.course.price - +this.course.oldPrice) / +this.course.oldPrice * 100
      return discount < 0 ? discount * -1 : discount
    },
    categoryName() {
      if(this.$store.state.lastRoute.name === 'courses')
        return this.$store.state.courses.categories.find(item => item.id === this.$store.state.courses.selectedCategoryId)?.name || 'Все направления'
      else if (this.course.categories.length)
        return this.$store.state.courses.categories.find(item => item.id === this.course.categories[0])?.name
      else return null
    },
    previewImage() {
      return this.courseInfo.videoIntro.preview instanceof Blob
          ? URL.createObjectURL(this.courseInfo.videoIntro.preview)
          : this.courseInfo.videoIntro.preview;
    }
  },
  methods: {
    clickButtonBuy() {
      this.$router.push({query: {enroll: null}})
      this.courseInfo.isPurchasable ? this.buyCourse() : this.NotAvailableCourse()
    },
    NotAvailableCourse() {
      this.$store.commit('myCourses.setShowNotAvailableCourse', true)
    },
    buyCourse() {
      this.$store.commit('myCourses.setShowBuyCoursePopup', true)
    },

  }
}
</script>

<style lang="scss" scoped>
.course-header-info {
  display: flex;

  margin-bottom: 140px;

  > .course-header-info__lesson-count {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    margin-bottom: 120px;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 100px;
  }
  @media screen and (max-width: 770px) {
    flex-direction: column;

    .course-header-info__lesson-count {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      margin: 40px auto 0 auto;
      background: rgba(34, 34, 34, 0.06);

      > div:first-child {
        margin-right: 60px;
      }
    }
  }
  @media screen and (max-width: 740px) {
    margin-bottom: 80px;
    .course-header-info__lesson-count {
      margin-top: 35px;
    }
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 70px;
    .course-header-info__lesson-count {
      border-radius: 18px;
      padding: 17px 30px;
      margin-top: 24px;
      div {
        font-size: 28px;

        &:first-child {
          margin-right: 50px;
        }

        span {
          font-size: 14px;
          margin-top: 6px;
        }
      }
    }
  }
  @media screen and (max-width: 360px) {
    .course-header-info__lesson-count {
      margin-top: 20px;
      padding: 13px 20px 14px 20px;

      > div:first-child {
        margin-right: 26px;
      }
    }
  }



  &__info {
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    max-width: 680px;

    width: 100%;

    &--w100 {
      max-width: 100%;
    }

    @media screen and (max-width: 770px) {
      padding: 0 10px;
      box-sizing: border-box;
      .course-header-info__lesson-count {
        display: none;
      }
    }
  }

  &__category {
    display: flex;
    margin-bottom: 16px;
    padding-top: 22px;

    div {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 100%;
      text-transform: lowercase;
      color: #1454F2;
      background: rgba(20, 84, 242, 0.1);
      border-radius: 200px;

      padding: 6px 20px 10px 20px;
    }

    div:last-child {
      color: #FF9F8C;
      background: rgba(255, 159, 140, 0.1);
      margin-left: 8px;
    }

    @media screen and (max-width: 980px) {
      div {
        font-size: 17px;
        padding: 5px 18px 8px 18px;
      }
    }
    @media screen and (max-width: 600px) {
      padding-top: 30px;
      div {
        font-size: 16px;
      }
    }
    @media screen and (max-width: 400px) {
      div {
        font-size: 13px;
        padding: 4px 14px 6px 14px;
      }
    }
  }

  &__title-course {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 100%;
    text-transform: lowercase;
    color: #222222;

    margin-bottom: 52px;

    @media screen and (max-width: 1200px) {
      font-size: 62px;
    }
    @media screen and (max-width: 980px) {
      font-size: 56px;
    }
    @media screen and (max-width: 740px) {
      font-size: 46px;
      margin-bottom: 46px;
    }
    @media screen and (max-width: 600px){
      font-size: 44px;
      margin-bottom: 28px;
    }
    @media screen and (max-width: 400px) {
      margin-bottom: 24px;
      font-size: 34px;
    }
  }

  &__buy-course {
    display: flex;
    align-items: center;

    margin-bottom: 82px;
    transition: 0.2s;

    @media screen and (max-width: 900px){
      margin-bottom: 68px;
    }

    @media screen and (max-width: 700px){
      margin-bottom: 60px;
    }
    @media screen and (max-width: 600px){
      margin-bottom: 46px;
    }
    @media screen and (max-width: 360px){
      margin-bottom: 40px;
    }
  }

  &__buy-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 282px;
    height: 74px;

    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;

    margin-right: 28px;

    color: #FFFFFF;
    background: #1454F2;
    border-radius: 18px;
    box-sizing: border-box;
    cursor: pointer;

    transition: 0.2s;

    &:hover {
      background-color: #4072ef;
    }

    @media screen and (max-width: 980px) {
      width: 260px;
    }

    @media screen and (max-width: 900px) {
      width: 250px;
      height: 68px;
    }

    @media screen and (max-width: 800px) {
      width: 230px;
    }

    @media screen and (max-width: 600px){
      width: 220px;
      height: 64px;
      border-radius: 16px;
    }
    @media screen and (max-width: 400px){
      padding: 0 44px;
      font-size: 14px;
      width: 174px;
      height: 56px;
      border-radius: 14px;
    }
  }

  &__price {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    color: #222222;
    white-space: nowrap;

    @media screen and (max-width: 980px) {
      font-size: 36px;
    }
    @media screen and (max-width: 600px) {
      font-size: 32px;
    }
    @media screen and (max-width: 400px) {
      font-size: 26px;
    }
  }

  &__lesson-count {
    display: flex;

    width: max-content;
    background: #F7F7F7;
    border-radius: 26px;
    padding: 21px 40px;

    div {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      color: #222222;

      &:first-child {
        margin-right: 40px;
      }

      span {
        display: block;
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 110%;
        color: #222222;
        opacity: 0.5;
        margin-top: 8px;
      }
    }

    @media screen and (max-width: 980px) {
      border-radius: 24px;
      div {
        font-size: 32px;

        &:first-child {
          margin-right: 36px;
        }

        span {
          font-size: 16px;
          margin-top: 6px;
        }
      }
    }
  }

  &__video-preview {
    position: relative;
    height: max-content;
    width: max-content;

    border: 4px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0 30px 150px -40px rgba(0, 0, 0, 0.2);
    border-radius: 26px;

    transition: 0.2s;

    &:after {
      content: '';
      position: absolute;
      width: 378px;
      height: 404px;

      bottom: -104px;
      right: -133px;

      background: #FF9F8C;
      opacity: 0.2;
      filter: blur(300px);
      border-radius: 26px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 378px;
      height: 404px;

      top: -132px;
      left: -133px;

      background: #1454F2;
      opacity: 0.2;
      filter: blur(300px);
      border-radius: 26px;
    }

    @media screen and (max-width: 770px) {
      width: 100%;
      margin: 0 auto;
      box-shadow: 0 17px 86px -23px rgb(0 0 0 / 20%);

      &:after {
        width: 300px;
        height: 320px;

        bottom: -150px;
        right: -30px;

        filter: blur(238px);
        border-radius: 21px;
      }
      &:before {
        width: 300px;
        height: 320px;

        top: -166px;
        left: -30px;

        filter: blur(238px);
        border-radius: 21px;
      }
    }
    @media screen and (max-width: 440px) {
      width: 100%;
      border-radius: 22px;

      &:after {
        width: 199px;
        height: 213px;

        bottom: -66px;
        right: -59px;

        filter: blur(158px);
        border-radius: 14px;
      }
      &:before {
        width: 199px;
        height: 213px;

        top: -94px;
        left: -59px;

        opacity: 0.1;
        filter: blur(158px);
        border-radius: 14px;
      }
    }
  }

  &__preview-img {
    position: relative;
    width: 480px;
    height: 490px;

    > img {
      height: 100%;
      width: 100%;

      object-fit: cover;
      border-top-left-radius: 26px;
      border-top-right-radius: 26px;
    }

    .play-preview {
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      content: '';
      width: 120px;
      height: 120px;

      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      margin: auto;
      cursor: pointer;

      background: rgba(255, 255, 255, 0.6);
      border-radius: 100%;
      backdrop-filter: blur(40px);


      transition: 0.2s;

      &:active {
        width: 114px;
        height: 114px;
      }

      @media screen and (max-width: 980px) {
        width: 104px;
        height: 104px;

        &:active {
          width: 98px;
          height: 98px;
        }

        img {
          width: 48px;
        }
      }

      @media screen and (max-width: 840px) {
        width: 90px;
        height: 90px;

        &:active {
          width: 84px;
          height: 84px;
        }

        img {
          width: 42px;
        }
      }

      @media screen and (max-width: 650px) {
        width: 80px;
        height: 80px;

        &:active {
          width: 74px;
          height: 74px;
        }

        img {
          width: 34px;
        }
      }
      @media screen and (max-width: 550px) {
        width: 70px;
        height: 70px;

        &:active {
          width: 64px;
          height: 64px;
        }

        img {
          width: 34px;
        }
      }
      @media screen and (max-width: 380px) {
        width: 60px;
        height: 60px;

        &:active {
          width: 56px;
          height: 56px;
        }

        img {
          width: 28px;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      width: 360px;
      height: 370px;
    }

    @media screen and (max-width: 840px) {
      width: 300px;
      height: 310px;
    }

    @media screen and (max-width: 770px) {
      width: 100%;
      height: 360px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      height: 290px;
    }
    @media screen and (max-width: 440px) {
      width: 100%;
      height: 100%;
      img {
        border-top-right-radius: 22px;
        border-top-left-radius: 22px;
        max-height: 360px;
      }
    }
    @media screen and (max-width: 390px) {
      img {
        max-height: 310px;
      }
    }
    @media screen and (max-width: 360px) {
      img {
        max-height: 280px;
      }
    }
    @media screen and (max-width: 340px) {
      img {
        max-height: 260px;
      }
    }
  }

  &__author {
    padding: 28px 20px 28px 40px;
    box-sizing: border-box;
    transition: 0.2s;
    width: 480px;

    @media screen and (max-width: 1200px) {
      width: 360px;
    }
    @media screen and (max-width: 980px) {
      padding: 24px 18px 24px 34px;
    }

    @media screen and (max-width: 840px) {
      padding: 22px 16px 22px 24px;
      width: 300px;
    }

    @media screen and (max-width: 770px) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 24px;
      row-gap: 6px;
      width: 100%;
      padding: 30px;
    }

    @media screen and (max-width: 440px) {
      padding: 16px 23px;
    }
  }

  &__author-name {
    display: flex;
    align-items: center;

    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 100%;
    color: #222222;

    margin-bottom: 12px;

    transition: 0.2s;
    img {
      margin-left: 10px;
    }

    @media screen and (max-width: 1200px) {
      font-size: 24px;
    }
    @media screen and (max-width: 770px) {
      font-size: 22px;
      margin-bottom: 0;
      img {
        width: 20px;
      }
    }
    @media screen and (max-width: 600px){
      font-size: 20px;
      margin-bottom: 8px;
      img {
        width: 18px;
      }
    }
    @media screen and (max-width: 500px) {
      margin-bottom: 6px;
      font-size: 18px;
      img {
        width: 16px;
      }
    }
    @media screen and (max-width: 400px) {
      font-size: 16px;
    }
    @media screen and (max-width: 320px) {
      font-size: 15px;
    }
  }

  &__author-position {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #222222;
    opacity: 0.5;

    @media screen and (max-width: 1200px) {
      font-size: 15px;
    }
    @media screen and (max-width: 770px) {
      font-size: 14px;
    }
    @media screen and (max-width: 500px) {
      font-size: 13px;
    }
    @media screen and (max-width: 400px) {
      font-size: 12px;
    }
    @media screen and (max-width: 320px) {
      font-size: 9px;
    }
  }

}
</style>
